import { Card } from "primereact/card";
import * as client from "../../../client";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import styles from "./NewsCard.module.css";
import { groupBy } from "lodash";

type NewsCardProps = {
  newsInfo: client.NewsInfo[];
};

export const NewsCard: React.FC<NewsCardProps> = ({ newsInfo }) => {
  // Group by unique urls
  const urlsToDescriptions = groupBy(newsInfo, "url");

  return (
    <Card title="Company News">
      {newsInfo.length > 0 ? (
        <div className={styles.cardContainer}>
          {Object.entries(urlsToDescriptions).map(([url, messages]) => {
            return (
              <div key={url} className={styles.container}>
                <Button
                  text
                  raised
                  severity="secondary"
                  onClick={() => window.open(url, "_blank")}
                  label="Source"
                />
                <div className={styles.warningContainer}>
                  {messages.map((message) => (
                    <Message
                      severity="warn"
                      text={message.description}
                      key={message.description}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <span>No company news found</span>
      )}
    </Card>
  );
};
