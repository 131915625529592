import styles from "./Conversation.module.css";
import { InputTextarea } from "primereact/inputtextarea";
import { useRef, useState } from "react";
import { sleepMs } from "../../util";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { continueCommercialConversation } from "../../api/routes";
import * as client from "../../client";
import Markdown from "react-markdown";

type ConversationProps = {
  specialtyPropertyInfo: client.ReportResponse;
};

export const Conversation: React.FC<ConversationProps> = ({
  specialtyPropertyInfo,
}) => {
  const [messageText, setMessageText] = useState("");
  const [isLoadingQuestionResponse, setIsLoadingQuestionResponse] =
    useState(false);

  const conversationContainerRef = useRef<HTMLDivElement>(null);

  const [conversationStream, setConversationStream] = useState<
    client.Message[]
  >([]);

  const sampleQuestions = [
    "What states does this company operate in?",
    "What type of losses has this company experienced most frequently in the past?",
    "What are some of the highest risk natural disasters in areas where the company has a lot of total insured value?",
  ];

  const scrollToBottom = () => {
    sleepMs(50).then(() => {
      conversationContainerRef.current?.scrollTo({
        top: conversationContainerRef.current?.scrollHeight ?? 0,
        behavior: "smooth",
      });
    });
  };

  const askNewQuestion = async (message: string) => {
    const newMessages: client.Message[] = [
      ...conversationStream,
      {
        is_user_message: true,
        is_visible: true,
        content: message,
        original: JSON.stringify({
          role: "user",
          content: message,
        }),
      },
    ];
    setConversationStream(newMessages);
    setMessageText("");
    setIsLoadingQuestionResponse(true);
    scrollToBottom();

    const newMessageList = await continueCommercialConversation(
      specialtyPropertyInfo.report_id,
      newMessages
    );

    setIsLoadingQuestionResponse(false);
    if (newMessageList !== undefined) {
      setConversationStream(newMessageList);
      scrollToBottom();
    } else {
      // TODO: failure toast
    }
  };

  return (
    <div className={styles.container}>
      {conversationStream.length > 0 ? (
        <div
          className={styles.conversationContainer}
          ref={conversationContainerRef}
        >
          {conversationStream
            .filter((m) => m.is_visible)
            .map((message, index) => (
              <div
                className={styles.conversationBubble}
                key={index}
                style={{
                  backgroundColor: message.is_user_message
                    ? "var(--surface-700)"
                    : "var(--surface-200)",
                  color: message.is_user_message
                    ? "var(--surface-50)"
                    : "var(--text-color)",
                  alignSelf: message.is_user_message
                    ? "flex-end"
                    : "flex-start",
                }}
              >
                <Markdown>{message.content}</Markdown>
              </div>
            ))}
          {isLoadingQuestionResponse && (
            <div
              className={styles.conversationBubble}
              style={{
                backgroundColor: "var(--surface-200)",
                color: "var(--text-color)",
                alignSelf: "flex-start",
                padding: "0px",
              }}
            >
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px", width: "160px" }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.emptyStateContainer}>
          {sampleQuestions.map((question, index) => (
            <div
              key={index}
              className={styles.sampleQuestionContainer}
              tabIndex={0}
              role="button"
              onClick={() => askNewQuestion(question)}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  askNewQuestion(question);
                }
              }}
            >
              {question}
            </div>
          ))}
        </div>
      )}
      <div className={styles.inputContainerWrapper}>
        <div className={styles.inputContainer}>
          <InputTextarea
            placeholder={`Ask a question about ${specialtyPropertyInfo.report_json.company_info.company_name}...`}
            className={styles.textInput}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            autoResize={true}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                if (messageText !== "" && !isLoadingQuestionResponse) {
                  askNewQuestion(messageText);
                }
                event.preventDefault();
              }
            }}
          />
          <Button
            icon="pi pi-chevron-right"
            className={styles.inputButton}
            disabled={messageText === "" || isLoadingQuestionResponse}
            onClick={() => askNewQuestion(messageText)}
          />
        </div>
      </div>
    </div>
  );
};
