import { Dialog } from "primereact/dialog";
import * as client from "../../../client";
import styles from "./ColumnMappingsModal.module.css";
import { TabPanel, TabView } from "primereact/tabview";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ThemeContext } from "../../../themes/Theme";
import {
  borderColorForAttributes,
  colorForAttributes,
  Severity,
} from "../dashboard/util";
import { isEqual, clone } from "lodash";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useUpdateReport } from "../data";

type ColumnMappingsModalProps = {
  sovs: client.Sov[];
  reportId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const ColumnMappingsModal: React.FC<ColumnMappingsModalProps> = ({
  sovs,
  reportId,
  visible,
  setVisible,
}) => {
  const [newSovs, setNewSovs] = useState<client.Sov[]>(() =>
    JSON.parse(JSON.stringify(sovs))
  );
  useEffect(() => {
    setIsSaving(false);
    setNewSovs(JSON.parse(JSON.stringify(sovs)));
  }, [sovs]);

  const [isSaving, setIsSaving] = useState(false);

  const updateReport = useUpdateReport(reportId);

  const saveEdits = async () => {
    // Is saving will be set to false automatically once this succeeds
    setIsSaving(true);
    updateReport.mutate({ sovs: newSovs, rerunReport: true });
  };

  const sovsAreEqual = isEqual(sovs, newSovs);

  return (
    <Dialog
      header={
        <div className={styles.buttonContainer}>
          <span style={{ marginRight: "16px" }}>Column Mappings</span>
          <Button
            label="Save Edits and Rerun"
            disabled={sovsAreEqual}
            loading={isSaving}
            onClick={() => saveEdits()}
          />
          <Button
            severity="secondary"
            label="Clear Edits"
            disabled={sovsAreEqual}
            onClick={() => {
              setNewSovs(JSON.parse(JSON.stringify(sovs)));
            }}
          />
        </div>
      }
      visible={visible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={() => setVisible(false)}
    >
      <TabView scrollable>
        {newSovs.map((sov) => (
          <TabPanel key={sov.filename} header={sov.filename}>
            <SovColumnSet sov={sov} setNewSovs={setNewSovs} />
          </TabPanel>
        ))}
      </TabView>
    </Dialog>
  );
};

type SovColumnSetProps = {
  sov: client.Sov;
  setNewSovs: Dispatch<SetStateAction<client.Sov[]>>;
};

const SovColumnSet: React.FC<SovColumnSetProps> = ({ sov, setNewSovs }) => {
  if (!sov.column_mappings) {
    return <></>;
  }
  const columnMappings = sov.column_mappings;
  const rmsKeys = Object.keys(RMS_MAPPINGS) as RmsKey[];

  return (
    <div className={styles.columnMappingSet}>
      {rmsKeys.map((rmsKey) => {
        const attributeName = RMS_MAPPINGS[rmsKey];
        let mappingInfo: client.MappingInfo | undefined;
        if (sov.column_overrides && attributeName in sov.column_overrides) {
          mappingInfo = {
            original_col_name: sov.column_overrides[attributeName],
            // Use something over 100 to indicate a change by the user
            confidence: 150,
          };
        } else if (attributeName in columnMappings) {
          mappingInfo = columnMappings[attributeName];
        }
        return (
          <ColumnMappingRow
            key={rmsKey}
            sov={sov}
            finalColumnName={rmsKey}
            originalColumnName={
              mappingInfo ? mappingInfo.original_col_name : undefined
            }
            documentHeaders={sov.all_document_headers ?? []}
            confidence={mappingInfo ? mappingInfo.confidence : undefined}
            setNewSovs={setNewSovs}
          />
        );
      })}
    </div>
  );
};

type ColumnMappingRowProps = {
  sov: client.Sov;
  originalColumnName: string | undefined;
  finalColumnName: RmsKey;
  documentHeaders: string[];
  confidence: number | undefined;
  setNewSovs: Dispatch<SetStateAction<client.Sov[]>>;
};

const ColumnMappingRow: React.FC<ColumnMappingRowProps> = ({
  sov,
  finalColumnName,
  originalColumnName,
  documentHeaders,
  confidence,
  setNewSovs,
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const documentHeaderCodes = documentHeaders.map((header) => {
    return { code: header };
  });

  let confidenceSeverity: Severity = "neutral";
  if (confidence !== undefined && confidence > 100) {
    confidenceSeverity = "other";
  } else if (confidence !== undefined && confidence === 100) {
    confidenceSeverity = "good";
  } else if (confidence !== undefined && confidence >= 0) {
    confidenceSeverity = "medium";
  }
  const attributeName = RMS_MAPPINGS[finalColumnName];

  return (
    <div className={styles.columnMappingRow}>
      <Dropdown
        value={{ code: originalColumnName }}
        options={documentHeaderCodes}
        showClear={
          sov.column_overrides !== undefined &&
          sov.column_overrides !== null &&
          attributeName in sov.column_overrides
        }
        optionLabel="code"
        placeholder="None found"
        style={{ width: "300px" }}
        panelClassName={styles.dropdownPanel}
        onChange={(e) => {
          setNewSovs((sovs) => {
            const newSovs = clone(sovs);
            const newSov = newSovs.find(
              (oldSov) => oldSov.filename === sov.filename
            );
            if (newSov === undefined) {
              return newSovs;
            }
            const newOverrides = newSov?.column_overrides ?? {};
            if (e.value) {
              newOverrides[attributeName] = e.value.code;
            } else {
              delete newOverrides[attributeName];
            }
            newSov.column_overrides =
              Object.keys(newOverrides).length === 0 ? null : newOverrides;
            return newSovs;
          });
        }}
      />
      <i className="pi pi-arrow-right" />
      <span
        className={styles.columnText}
        style={{
          backgroundColor: colorForAttributes(confidenceSeverity, isLightMode),
          borderColor: borderColorForAttributes(
            confidenceSeverity,
            isLightMode
          ),
        }}
      >
        {finalColumnName}
      </span>
    </div>
  );
};

const RMS_MAPPINGS = {
  LATITUDE: "lat",
  LONGITUDE: "long",
  YEARBUILT: "year_built",
  NUMSTORIES: "number_of_stories",
  NUMBLDGS: "number_of_buildings",
  FLOORAREA: "square_footage",
  SHAPECONF: "plan_irregularity",
  STORYPROF: "soft_story",
  OVERPROF: "vertical_irregularity",
  CLADDING: "eq_cladding_type",
  SHORTCOL: "short_column",
  URMCHIMNEY: "urm_chimney",
  MASINTPART: "urm_partition",
  ORNAMENT: "ornamentation",
  WALLSBRACD: "cripple_walls",
  FRAMEBOLT: "frame_bolted_down",
  TILTUPRET: "anchoring",
  URMPROV: "urm_retrofit",
  STRUCTUP: "structural_upgrade",
  ENGFOUND: "engineered_foundation",
  MECHELEC: "equipment_eq_bracing",
  CONQUAL: "eq_construction_quality",
  DURESS: "equipment_support_maintenance",
  POUNDING: "pounding",
  BASEISOL: "base_isolation",
  SPNKLRTYPE: "sprinkler_type",
  EQSLSUSCEPTIBILITY: "eqsl_susceptibility",
  CONSTQUALI: "ws_construction_quality",
  ROOFGEOM: "roof_geometry",
  ROOFANCH: "roof_anchor",
  ROOFEQUIP: "roof_equipment_hurricane_bracing",
  EXTORN: "commercial_appurtenant_structures",
  ROOFSYS: "roof_covering",
  ROOFAGE: "roof_age_or_condition",
  CLADSYS: "ws_cladding_type",
  CLADRATE: "roof_sheathing_attachment",
  FOUNDSYS: "frame_foundation_connection",
  ARCHITECT: "residential_appurtenant_structures",
  MECHGROUND: "ground_level_equipment",
  RESISTOPEN: "opening_protection",
  FLASHING: "flashing_and_coping_quality",
  HUFLOORTYPE: "floor_type",
  FLOODMISSL: "flood_missiles",
  FLOODPROT: "flood_protection",
  BASEMENT: "basement_protection",
  IFMVERTICALEXPDIST: "vertical_exposure_distribution",
  PREPAREDNESS: "bi_preparedness",
  REDUNDANCY: "bi_redundancy",
};
type RmsKey = keyof typeof RMS_MAPPINGS;
