import { Card } from "primereact/card";
import type {
  ApiLocation,
  ApiCondition,
  ApiDeductibleForPeril,
  DeductibleInfo,
} from "../../client";
import { AbbreviatedMoney } from "./Money";
import { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, ButtonProps } from "primereact/button";
import styles from "./PolicyCard.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { groupBy } from "lodash";
import { STATE_ABBREV_TO_STATE_NAME, StateAbbrev } from "../../datasets";

type Peril = "flood" | "earthquake" | "named_storm" | "aop";

type LocationsForList = (name: string) => {
  locations: ApiLocation[];
  excluding: ApiLocation[];
};

export const PolicyCard = ({ info }: { info: DeductibleInfo }) => {
  const locationsForList: LocationsForList = (name: string) => {
    const list = info.named_lists_content.lists.find(
      (list) => list.list_name === name
    );
    return {
      locations: list?.locations ?? [],
      excluding: list?.excluded_locations ?? [],
    };
  };

  const warnings: Flag[] = (info.flag_reasons ?? []).map((explanation) => ({
    explanation,
    type: "Warning",
  }));
  const failures: Flag[] = info.failure_explanation
    ? [
        {
          explanation: info.failure_explanation,
          type: "Failure",
        },
      ]
    : [];

  const anyData =
    info.flood_specific_deductible ||
    info.earthquake_specific_deductible ||
    info.named_storm_specific_deductible ||
    info.all_others_specific_deductible ||
    warnings.length ||
    failures.length;

  return (
    <Card title="Policy Information">
      {!anyData ? (
        "We were unable to process the policy documents for this submission"
      ) : (
        <div className={styles.policyCard}>
          <FlagSection flags={failures.concat(warnings)} />
          <PerilSection
            name="Flood"
            type="flood"
            peril={info.flood_specific_deductible}
            iconName="pi-cloud"
            locationsForList={locationsForList}
          />
          <PerilSection
            name="Named Storm"
            type="named_storm"
            iconName="pi-bolt"
            peril={info.named_storm_specific_deductible}
            locationsForList={locationsForList}
          />
          <PerilSection
            name="Earthquake"
            type="earthquake"
            iconName="pi-image"
            peril={info.earthquake_specific_deductible}
            locationsForList={locationsForList}
          />
          <PerilSection
            name="All Other Perils"
            type="aop"
            iconName="pi-exclamation-triangle"
            peril={info.all_others_specific_deductible}
            locationsForList={locationsForList}
          />
        </div>
      )}
    </Card>
  );
};

type Flag = { explanation: string; type: "Warning" | "Failure" };
const FlagSection = ({ flags }: { flags: Flag[] }) => {
  if (!flags.length) {
    return null;
  }

  return (
    <Card
      title={
        <div className={styles.titleContainer}>
          <i
            className="pi pi-exclamation-circle"
            style={{
              fontSize: "20px",
              color: "var(--text-color)",
              fontWeight: "bold",
            }}
          ></i>
          <span className={styles.perilCardTitle}>Human Review Required</span>
        </div>
      }
      className={styles.perilCard}
    >
      <ul>
        {flags.map((f) => (
          <li key={JSON.stringify(f)}>
            {f.type}: {f.explanation}
          </li>
        ))}
      </ul>
    </Card>
  );
};

const PerilSection = ({
  name,
  type,
  peril,
  iconName,
  locationsForList,
}: {
  name: string;
  type: Peril;
  peril: ApiDeductibleForPeril | null | undefined;
  iconName: string;
  locationsForList: LocationsForList;
}) => {
  const [showPolicyExcerpt, setShowPolicyExcerpt] = useState(false);
  if (!peril?.filter_fallthrough) {
    return null;
  }

  const color = {
    flood: "var(--cyan-500)",
    named_storm: "var(--yellow-500)",
    earthquake: "var(--red-800)",
    aop: "var(--text-color)",
  }[type];

  return (
    <Card
      title={
        <div className={styles.titleContainer}>
          <i
            className={`pi ${iconName}`}
            style={{ fontSize: "20px", color: color, fontWeight: "bold" }}
          ></i>
          <span className={styles.perilCardTitle}>{`${name} Deductible`}</span>
        </div>
      }
      className={styles.perilCard}
    >
      <div className={styles.perilCardContainer}>
        {peril.filter_fallthrough.map(
          ({
            condition,
            deductible_flat_dollar_amount: flat,
            deductible_percentage_0_to_100: percent,
            not_covered: ncp,
          }) => (
            <div
              key={JSON.stringify(condition)}
              className={styles.deductibleContainer}
            >
              <div className={styles.deductible}>
                {ncp ? (
                  "Not covered"
                ) : (
                  <>
                    {flat && <AbbreviatedMoney dollars={flat} />}
                    {flat && percent ? " or " : ""}
                    {percent ? `${percent.toFixed(1)}%` : ""}
                  </>
                )}
              </div>
              <div className={styles.condition}>
                <Condition
                  condition={condition}
                  locationsForList={locationsForList}
                  isOnlyCondition={peril.filter_fallthrough?.length === 1}
                />
              </div>
            </div>
          )
        )}
        <TinyButton
          severity="info"
          text
          onClick={() => setShowPolicyExcerpt((v) => !v)}
          size="small"
          icon={showPolicyExcerpt ? "pi pi-angle-down" : "pi pi-angle-up"}
          label={`${showPolicyExcerpt ? "Hide" : "Show"} policy excerpt`}
          style={{ marginTop: "8px" }}
        />
        {showPolicyExcerpt && (
          <div className={styles.excerptContainer}>
            <div className={styles.verticalDivider} />
            <span className={styles.policyExcerpt}>{peril.excerpt}</span>
          </div>
        )}
      </div>
    </Card>
  );
};

const MAX_LOCATIONS_INLINE = 5;

const Condition = ({
  condition,
  locationsForList,
  isOnlyCondition,
}: {
  condition: ApiCondition;
  locationsForList: LocationsForList;
  isOnlyCondition: boolean;
}) => {
  switch (condition.type) {
    case "always_true":
      return isOnlyCondition ? <>For all locations</> : <>Otherwise</>;
    case "is_in_named_list":
      return (
        <>
          If location is in
          <ClickableTagWithDialog label={condition.list_name ?? ""}>
            <LocationList {...locationsForList(condition.list_name ?? "")} />
          </ClickableTagWithDialog>
        </>
      );
    case "is_special_flood_hazard_area":
      return (
        <>
          If location is in
          <ClickableTagWithDialog label="Special Flood Hazard Area"></ClickableTagWithDialog>
        </>
      );
    case "location":
      return (
        <>
          If location is in{" "}
          {condition.valid_locations
            .slice(0, MAX_LOCATIONS_INLINE)
            .map(formatLocation)
            .join(", ")}
          {condition.valid_locations.length > MAX_LOCATIONS_INLINE && (
            <ShowFullLocationListButton locations={condition.valid_locations} />
          )}
        </>
      );
    case "address_list":
      return (
        <>
          [NOT IMPLEMENTED] If location is in{" "}
          {condition.address_list.join(", ")}
        </>
      );
  }
};

const formatLocation = (location: ApiLocation): string => {
  if (location.county) {
    return `${location.state} / ${location.county}`;
  } else {
    return `${location.state} (all counties)`;
  }
};

const ShowFullLocationListButton = ({
  locations,
}: {
  locations: ApiLocation[];
}) => {
  return (
    <ClickableTagWithDialog
      label={`${locations.length - MAX_LOCATIONS_INLINE} more`}
    >
      <LocationList locations={locations} />
    </ClickableTagWithDialog>
  );
};

const LocationList = ({
  locations,
  excluded,
}: {
  locations: ApiLocation[];
  excluded?: ApiLocation[];
}) => {
  const locationsByState = useMemo(
    () => groupBy(locations, "state"),
    [locations]
  );
  const excludedByState = useMemo(
    () => excluded && groupBy(excluded, "state"),
    [excluded]
  );

  return (
    <DataTable
      value={Object.entries(locationsByState)}
      tableStyle={{ maxWidth: "800px" }}
    >
      <Column
        field="0"
        header="State"
        body={([state]: [StateAbbrev]) =>
          STATE_ABBREV_TO_STATE_NAME[state] ?? state
        }
      />
      <Column
        field="county"
        header="Counties"
        body={([state, locs]: [string, ApiLocation[]]) => {
          let description = "";
          if (locs.length === 1 && !locs[0].county) {
            description = "All counties";
          } else {
            description = locs.map(({ county }) => county).join(", ");
          }

          excluded = excludedByState?.[state];
          if (excluded && excluded.length > 0) {
            description +=
              ", but NOT including " +
              excluded.map(({ county }) => county).join(", ");
          }
          return description;
        }}
      />
    </DataTable>
  );
};

const ClickableTagWithDialog: React.FC<{
  label: string;
  children?: React.ReactNode;
}> = ({ label, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TinyButton
        size="small"
        severity="info"
        text
        label={label}
        onClick={() => setOpen(true)}
      />
      <Dialog
        style={{ minWidth: "600px" }}
        header={label}
        visible={open}
        onHide={() => setOpen(false)}
      >
        {children}
      </Dialog>
    </>
  );
};

const TinyButton: React.FC<ButtonProps> = (props) => (
  <Button {...props} className={styles.tinyButton} />
);
