import { Card } from "primereact/card";
import styles from "./LayersCard.module.css";
import { formatAbbreviatedMoney } from "../Money";

type InsuranceLayer = {
  startingPoint: number;
  range: number;
  rated: number | null;
  target: number | null;
};

type LayersCardProps = {
  sortedLayers: InsuranceLayer[];
};

export const LayersCard: React.FC<LayersCardProps> = ({ sortedLayers }) => {
  const layerRanges = Array.from(
    new Set(sortedLayers.map((layer) => layer.range))
  ).sort((a, b) => a - b);

  return (
    <Card title="Layers">
      <div className={styles.container}>
        <div className={styles.barGraphContainer}>
          {sortedLayers.map((layer, index) => (
            <BarGraphComponent
              maxIndex={sortedLayers.length - 1}
              index={index}
              layer={layer}
              key={index}
              layerRanges={layerRanges}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

const textColorForIndex = (index: number, maxIndex: number) => {
  if (maxIndex > 8) {
    index -= 1;
  }
  if (index <= 4) {
    return "var(--bluegray-900)";
  }
  return "var(--bluegray-50)";
};

const backgroundColorForIndex = (index: number, maxIndex: number) => {
  if (maxIndex <= 8) {
    return `var(--cyan-${index + 1}00)`;
  }

  if (index == 0) {
    return `var(--cyan-50)`;
  }
  return `var(--cyan-${index}00)`;
};

type BarGraphComponentProps = {
  index: number;
  maxIndex: number;
  layer: InsuranceLayer;
  layerRanges: number[];
};

const BarGraphComponent: React.FC<BarGraphComponentProps> = ({
  index,
  maxIndex,
  layer,
  layerRanges,
}) => {
  const { startingPoint, range, target } = layer;
  const indexOfRange = layerRanges.findIndex((r) => r === range);

  // The smallest layer will always be 60px, then we will add 12 for each level bigger
  const layerHeightInPixels = 80 + indexOfRange * 20;
  const readableTitle =
    startingPoint === 0
      ? `Primary ${formatAbbreviatedMoney(range)}`
      : `${formatAbbreviatedMoney(range)} xs ${formatAbbreviatedMoney(
          startingPoint
        )}`;
  const svgLineHeight = (layerHeightInPixels - 22) / 2;

  return (
    <div className={styles.barRowContainer}>
      <div
        className={styles.rangeContainer}
        style={{
          height: `${layerHeightInPixels - 6}px`,
        }}
      >
        <TLineTop height={svgLineHeight} />
        <span className={styles.rangeText}>
          {formatAbbreviatedMoney(range)}
        </span>
        <TLineBottom height={svgLineHeight} />
      </div>
      <div
        className={styles.barGraphComponent}
        style={{
          height: `${layerHeightInPixels}px`,
          backgroundColor: backgroundColorForIndex(index, maxIndex),
        }}
      >
        <span style={{ color: textColorForIndex(index, maxIndex) }}>
          {readableTitle}
        </span>
      </div>
      <div
        className={styles.ratingContainer}
        style={{
          height: `${layerHeightInPixels}px`,
        }}
      >
        <span>
          {target === null
            ? "No target given"
            : `Target: ${formatAbbreviatedMoney(target)}`}
        </span>
      </div>
    </div>
  );
};

const TLineTop = ({ height }: { height: number }) => (
  <svg width="16" height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d={`M8 ${height} L8 0 M0 0 L16 0`}
      stroke="var(--surface-400)"
      strokeWidth="1"
    />
  </svg>
);

const TLineBottom = ({ height }: { height: number }) => (
  <svg width="16" height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      d={`M8 ${height} L8 0 M0 ${height} L16 ${height}`}
      stroke="var(--text-color-secondary)"
      strokeWidth="1"
    />
  </svg>
);
