import { useMemo } from "react";
import * as client from "../../../client";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatAbbreviatedMoney } from "../Money";
import { Card } from "primereact/card";
import { Fieldset } from "primereact/fieldset";
import styles from "./SummaryCard.module.css";
import { TerribleMoney } from "../loss-run/LossRunCard";
import { getNumYearsAgo } from "../loss-run/DeductibleCalculator";
import { reverse, sortBy } from "lodash";
import { sortedLayersFromReport } from "../layers/util";

type SummaryCardProps = {
  specialtyPropertyInfo: client.ReportResponse;
  forPrint: boolean;
};

export const SummaryCard: React.FC<SummaryCardProps> = ({
  specialtyPropertyInfo,
  forPrint,
}) => {
  const addresses = useMemo(
    () =>
      specialtyPropertyInfo.report_json.sovs
        .filter((sov) => sov.is_enabled)
        .flatMap((sov) => sov.addresses),
    [specialtyPropertyInfo]
  );
  const totalTIV = useMemo(
    () => addresses.reduce((total, address) => total + address.tiv, 0),
    [addresses]
  );

  return (
    <Card title="Summary">
      {forPrint ? (
        <div className={styles.printContainer}>
          <Fieldset legend="TIV/Loss History">
            <TivAndLossHistory
              specialtyPropertyInfo={specialtyPropertyInfo}
              totalTIV={totalTIV}
            />
          </Fieldset>
          <Fieldset legend="TIV Summary (State by State)">
            <StateTivTable specialtyPropertyInfo={specialtyPropertyInfo} />
          </Fieldset>
          <Fieldset legend="CAT Events">
            <CatEvents totalTIV={totalTIV} />
          </Fieldset>
          <Fieldset legend="Layers By Year">
            <LayersByYear specialtyPropertyInfo={specialtyPropertyInfo} />
          </Fieldset>
        </div>
      ) : (
        <Accordion>
          <AccordionTab header="TIV/Loss History">
            <TivAndLossHistory
              specialtyPropertyInfo={specialtyPropertyInfo}
              totalTIV={totalTIV}
            />
          </AccordionTab>
          <AccordionTab header="TIV Summary (State by State)">
            <StateTivTable specialtyPropertyInfo={specialtyPropertyInfo} />
          </AccordionTab>
          <AccordionTab header="CAT Events">
            <CatEvents totalTIV={totalTIV} />
          </AccordionTab>
          <AccordionTab header="Layers By Year">
            <LayersByYear specialtyPropertyInfo={specialtyPropertyInfo} />
          </AccordionTab>
        </Accordion>
      )}
    </Card>
  );
};

const LayersByYear = ({
  specialtyPropertyInfo,
}: {
  specialtyPropertyInfo: client.ReportResponse;
}) => {
  const currentYear = new Date().getFullYear();
  const yearsBack = [0, 1, 2, 3, 4];
  const layersForSingleYear = sortedLayersFromReport(
    specialtyPropertyInfo
  ).reverse();
  const addresses = useMemo(
    () =>
      specialtyPropertyInfo.report_json.sovs
        .filter((sov) => sov.is_enabled)
        .flatMap((sov) => sov.addresses),
    [specialtyPropertyInfo]
  );

  const allLayers: {
    startingPoint: number;
    range: number;
    target: number | null;
    rated: number | null;
    year: number;
    tiv: number;
    layerNumber: number;
  }[] = [];
  for (const yearBack of yearsBack) {
    let count = 0;
    for (const layer of layersForSingleYear) {
      let tivForLayer = 0;
      for (const address of addresses) {
        if (address.tiv > layer.startingPoint) {
          tivForLayer += Math.min(
            layer.range,
            address.tiv - layer.startingPoint
          );
        }
      }
      const newLayer = {
        ...layer,
        target: layer.target ? layer.target * 0.96 ** yearBack : null,
        year: currentYear - yearBack,
        layerNumber: count,
        tiv: tivForLayer * 0.95 ** yearBack,
      };
      allLayers.push(newLayer);
      count += 1;
    }
  }
  return (
    <DataTable value={allLayers}>
      <Column header="Year" field="year" />
      <Column header="Layer Number" field="layerNumber" />
      <Column
        header="Layer"
        body={(layer) =>
          `${formatAbbreviatedMoney(
            layer.startingPoint
          )}-${formatAbbreviatedMoney(layer.startingPoint + layer.range)}`
        }
      />
      <Column
        header="Premium"
        body={(layer) =>
          layer.target ? formatAbbreviatedMoney(layer.target) : "None Found"
        }
      />
      <Column
        header="TIV"
        body={(layer) => formatAbbreviatedMoney(layer.tiv)}
      />
      <Column
        header="TIV / Prem"
        body={(layer) =>
          layer.target && layer.target !== 0
            ? (layer.tiv / layer.target).toFixed(2)
            : "No Premium Found"
        }
      />
      <Column
        header="Rate Change"
        body={(layer) => {
          const layerOneYearAgo = allLayers.find(
            (otherLayer) =>
              otherLayer.year === layer.year - 1 &&
              otherLayer.layerNumber === layer.layerNumber
          );
          if (
            layerOneYearAgo &&
            layerOneYearAgo.target &&
            layerOneYearAgo.target !== 0 &&
            layer.target &&
            layer.target !== 0
          ) {
            const ratioNow = layer.tiv / layer.target;
            const ratioLastYear = layerOneYearAgo.tiv / layerOneYearAgo.target;
            const newerIsBigger = ratioNow >= ratioLastYear;
            const rateChange = (
              (newerIsBigger
                ? (ratioNow - ratioLastYear) / ratioNow
                : (ratioLastYear - ratioNow) / ratioLastYear) * 100
            ).toFixed(2);
            return newerIsBigger ? `-${rateChange} %` : `+${rateChange} %`;
          }

          return "";
        }}
      />
    </DataTable>
  );
};

const CatEvents = ({ totalTIV }: { totalTIV: number }) => {
  const exampleInfo = [
    {
      frequency: "1/25",
      namedWind: 0.00076470588 * totalTIV,
      caEq: 0.00004352941 * totalTIV,
      pnwEq: 0,
      nmEq: 0,
    },
    {
      frequency: "1/100",
      namedWind: 0.00252941176 * totalTIV,
      caEq: 0.0009117647 * totalTIV,
      pnwEq: 0,
      nmEq: 0,
    },
    {
      frequency: "1/250",
      namedWind: 0.00382352941 * totalTIV,
      caEq: 0.00176470588 * totalTIV,
      pnwEq: 0.00014705882 * totalTIV,
      nmEq: 0,
    },
  ];
  return (
    <DataTable value={exampleInfo}>
      <Column header="Frequency" field="frequency" />
      <Column
        header="Named Wind"
        body={(data) => formatAbbreviatedMoney(data.namedWind)}
      />
      <Column
        header="CA EQ"
        body={(data) => formatAbbreviatedMoney(data.caEq)}
      />
      <Column
        header="PNW EQ"
        body={(data) => formatAbbreviatedMoney(data.pnwEq)}
      />
      <Column
        header="NM EQ"
        body={(data) => formatAbbreviatedMoney(data.nmEq)}
      />
    </DataTable>
  );
};

const TivAndLossHistory = ({
  specialtyPropertyInfo,
  totalTIV,
}: {
  specialtyPropertyInfo: client.ReportResponse;
  totalTIV: number;
}) => {
  let lossRuns =
    specialtyPropertyInfo.report_json.loss_run.computed_loss_runs_by_year
      .length > 0
      ? specialtyPropertyInfo.report_json.loss_run.computed_loss_runs_by_year
      : specialtyPropertyInfo.report_json.loss_run.parsed_loss_runs_by_year;
  lossRuns = reverse(sortBy(lossRuns, "policy_year"));

  return (
    <DataTable value={lossRuns}>
      <Column sortable header="Year" field="policy_year"></Column>
      <Column
        field="gross_loss_paid"
        header="Ground Up Loss"
        body={(data: client.LossRunByYear) => (
          <TerribleMoney
            dollars={data.gross_loss_paid.value}
            fieldName="Ground Up Loss"
            errors={data.gross_loss_paid.errors}
          />
        )}
      />
      <Column
        header="TIV"
        body={(data: client.LossRunByYear) => {
          const numYearsAgo = getNumYearsAgo(data.policy_year);
          const tiv = numYearsAgo ? totalTIV * 0.95 ** numYearsAgo : 0;
          return <TerribleMoney dollars={tiv} fieldName="TIV" />;
        }}
      />
      <Column
        header="Loss/TIV"
        body={(data: client.LossRunByYear) => {
          const numYearsAgo = getNumYearsAgo(data.policy_year);
          const tiv = numYearsAgo ? totalTIV * 0.95 ** numYearsAgo : 0;
          const ratio = tiv === 0 ? 0 : data.gross_loss_paid.value / tiv;
          return ratio.toFixed(5);
        }}
      />
    </DataTable>
  );
};

const StateTivTable = ({
  specialtyPropertyInfo,
}: {
  specialtyPropertyInfo: client.ReportResponse;
}) => {
  const currentYear = new Date().getFullYear();
  const yearsBack = [0, 1, 2, 3, 4];

  const tivByStates = useMemo(() => {
    const stateToTiv: { [key: string]: number } = {};
    for (const sov of specialtyPropertyInfo.report_json.sovs) {
      if (sov.is_enabled) {
        for (const address of sov.addresses) {
          if (address.state && !(address.state in stateToTiv)) {
            stateToTiv[address.state] = 0;
          }
          if (address.state) {
            stateToTiv[address.state] += address.tiv;
          }
        }
      }
    }
    return stateToTiv;
  }, [specialtyPropertyInfo]);

  return (
    <DataTable value={Object.entries(tivByStates)}>
      <Column header="State" field="0"></Column>
      {yearsBack.map((yearBack) => (
        <Column
          header={currentYear - yearBack}
          field="1"
          key={yearBack}
          body={([, tiv]) => {
            const newValue = tiv * 0.95 ** yearBack;
            return formatAbbreviatedMoney(newValue);
          }}
        ></Column>
      ))}
    </DataTable>
  );
};
