import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as client from "../../../client";
import { ExactMoney, formatAbbreviatedMoney } from "../Money";
import { useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import styles from "./AddressCard.module.css";
import { Menu } from "primereact/menu";
import { downloadSov } from "../../../api/routes";
import { EditSovModal } from "./EditSovModal";
import { ColumnMappingsModal } from "./ColumnMappingsModal";
import { PantheonOnly } from "../../../dev";
import { useUpdateReport } from "../data";

type AddressCardProps = {
  reportId: string;
  companyName: string;
  sovs: client.Sov[];
  isCasualty: boolean;
  setMapVisible?: (visible: boolean) => void;
};

// XXX very dangerous
const TerribleMoney = ({ dollars }: { dollars?: number }) => {
  if (dollars === undefined) {
    return <></>;
  }
  const cents = Math.round(dollars * 100);
  return <ExactMoney cents={cents} />;
};

const download = async (
  reportId: string,
  format: string,
  companyName: string
) => {
  // Download the zip file as a blob
  const blob = await downloadSov(reportId, format);

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element and trigger a download
  const link = document.createElement("a");
  link.href = url;
  link.download = `${companyName}_SOV_${format}.xlsx`;
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const AddressCard: React.FC<AddressCardProps> = ({
  reportId,
  companyName,
  sovs,
  isCasualty,
  setMapVisible,
}) => {
  // Show enabled addresses
  const addresses = useMemo(
    () => sovs.filter((sov) => sov.is_enabled).flatMap((sov) => sov.addresses),
    [sovs]
  );

  const hasCountry = useMemo(
    () => addresses.every((a) => !!a.country),
    [addresses]
  );
  const totalTIV = useMemo(
    () => addresses.reduce((total, address) => total + address.tiv, 0),
    [addresses]
  );
  const totalBuildingValue = useMemo(
    () =>
      addresses.reduce(
        (total, address) =>
          total +
          (address.building_value && !isNaN(Number(address.building_value))
            ? Number(address.building_value)
            : 0),
        0
      ),
    [addresses]
  );
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [columnModalVisible, setColumnModalVisible] = useState(false);
  const updateReport = useUpdateReport(reportId);
  const isRerunning = updateReport.isPending;

  return (
    <Card
      title="Statement of Values"
      subTitle={
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitleContentsContainer}>
            <span>
              {isCasualty
                ? `Total Building Value: ${formatAbbreviatedMoney(
                    totalBuildingValue
                  )}`
                : `Total Insured Value: ${formatAbbreviatedMoney(totalTIV)}`}
            </span>
          </div>
          <div className={styles.subtitleContentsContainer}>
            {setMapVisible ? (
              <Button
                text
                raised
                severity="secondary"
                icon="pi pi-map"
                label="Show map"
                onClick={() => setMapVisible(true)}
              />
            ) : null}
            <DownloadActionMenu
              onDownloadRMS={() => download(reportId, "rms", companyName)}
              onDownloadHX={() => download(reportId, "hx", companyName)}
            />
            <Button
              text
              raised
              label="Edit"
              severity="secondary"
              icon="pi pi-file-edit"
              onClick={() => setEditModalVisible(true)}
            />
            {sovs.some(
              (sov) => sov.column_mappings && sov.all_document_headers
            ) && (
              <Button
                text
                raised
                label="Columns"
                severity="secondary"
                icon="pi pi-arrow-right"
                onClick={() => setColumnModalVisible(true)}
              />
            )}
            <PantheonOnly>
              <Button
                text
                raised
                label="Re-process"
                severity="danger"
                icon={isRerunning ? "pi pi-spin pi-spinner" : "pi pi-replay"}
                onClick={() => updateReport.mutate({ rerunReport: true })}
                disabled={isRerunning}
              />
            </PantheonOnly>
          </div>
        </div>
      }
    >
      <DataTable
        value={addresses}
        paginator
        rows={100}
        className={styles.table}
      >
        <Column
          field="street_address"
          header="Address"
          style={{ maxWidth: "200px" }}
        />
        <Column field="city" header="City" />
        <Column sortable field="state" header="State" />
        <Column field="zip" header="ZIP" />
        {hasCountry && <Column field="country" header="Country" />}
        <Column
          field="occupancy_code"
          header="Occupancy"
          body={(data: client.Address) =>
            `${data.occupancy_code_type} ${data.occupancy_code}`
          }
        />
        <Column
          field="construction_code"
          header="Construction"
          body={(data: client.Address) =>
            `${data.construction_code_type} ${data.construction_code}`
          }
        />
        <Column field="year_built" header="Year built" />
        <Column field="number_of_stories" header="Num Stories" />
        <Column
          field="building_value"
          header="Building Value"
          body={(data: client.Address) =>
            data.building_value && (
              <TerribleMoney dollars={parseFloat(data.building_value)} />
            )
          }
        />
        <Column
          field="bpp_value"
          header="BPP Value"
          body={(data: client.Address) =>
            data.bpp_value && (
              <TerribleMoney dollars={parseFloat(data.bpp_value)} />
            )
          }
        />
        <Column
          field="business_interruption_value"
          header="BI Value"
          body={(data: client.Address) =>
            data.business_interruption_value && (
              <TerribleMoney
                dollars={parseFloat(data.business_interruption_value)}
              />
            )
          }
        />
        {!isCasualty && (
          <Column
            sortable
            field="tiv"
            header="TIV"
            body={(data: client.Address) => (
              <TerribleMoney dollars={data.tiv} />
            )}
          />
        )}
        {[
          {
            name: "Flood Zone",
            field: "flood_zone",
            get: (data: client.Address) => data.flood_zone,
          },
          {
            name: "AOP Ded (% TIV)",
            field: "aop_deductible",
            get: (data: client.Address) => data.aop_deductible,
          },
          {
            name: "Fld Ded (% TIV)",
            field: "flood_deductible",
            get: (data: client.Address) => data.flood_deductible,
          },
          {
            name: "EQ Ded (% TIV)",
            field: "eq_deductible",
            get: (data: client.Address) => data.eq_deductible,
          },
          {
            name: "NS Ded (% TIV)",
            field: "named_storm_deductible",
            get: (data: client.Address) => data.named_storm_deductible,
          },
        ].map(({ field, name, get }) => (
          <Column
            key={field}
            sortable
            field={field}
            header={name}
            body={(data: client.Address) => {
              const val = get(data);
              if (typeof val === "number") {
                return (val * 100).toFixed(1) + "%";
              }
              return val;
            }}
          />
        ))}
      </DataTable>
      <EditSovModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        sovs={sovs}
        reportId={reportId}
      />
      <ColumnMappingsModal
        visible={columnModalVisible}
        setVisible={setColumnModalVisible}
        sovs={sovs}
        reportId={reportId}
      />
    </Card>
  );
};

type DownloadActionMenuProps = {
  onDownloadRMS: () => void;
  onDownloadHX: () => void;
};

const DownloadActionMenu: React.FC<DownloadActionMenuProps> = ({
  onDownloadRMS,
  onDownloadHX,
}) => {
  const menu = useRef<Menu>(null);
  const items = [
    {
      label: "Download",
      items: [
        {
          label: "RMS Sheet",
          command: onDownloadRMS,
        },
        {
          label: "HX Sheet",
          command: onDownloadHX,
        },
      ],
    },
  ];

  return (
    <div>
      <Menu model={items} popup ref={menu} />
      <Button
        text
        raised
        label="Download"
        severity="secondary"
        icon="pi pi-file-excel"
        onClick={(e) => {
          menu.current?.toggle(e);
        }}
      />
    </div>
  );
};
