import { useNavigate } from "react-router-dom";
import { articleContents } from "./article-contents/articlesContent";
import styles from "./ArticlesPage.module.css";
import { motion } from "framer-motion";
import { ROUTES } from "../../routes";
import { ThemeContext } from "../../themes/Theme";
import { useContext } from "react";

export const Articles = () => {
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  return (
    <div className={styles.articleCardContainer}>
      {articleContents.map((articleContent) => (
        <motion.div
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          whileFocus={{ scale: 1.05 }}
          transition={{ ease: "easeOut", duration: 0.1 }}
          key={articleContent.id}
          className={styles.articleCard}
          onClick={() => navigate(ROUTES.FULL_ARTICLE(articleContent.id))}
          style={{
            backgroundColor: isLightMode
              ? "rgba(240, 240, 240, 0.4)"
              : "rgba(40, 40, 40, 0.4)",
          }}
          tabIndex={0}
          role="button"
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              navigate(ROUTES.FULL_ARTICLE(articleContent.id));
            }
          }}
        >
          <img src={articleContent.mainImgSrc} className={styles.mainImg} />
          <span className={styles.articleTitleText}>
            {articleContent.title}
          </span>
        </motion.div>
      ))}
    </div>
  );
};
