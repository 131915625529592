import styles from "./Landing.module.css";
import { motion, useInView } from "framer-motion";
import landingImage from "./landingText.svg";
import dashboardImage from "./dashboard.png";
import policyImage from "./policyInfo.png";
import chatImage from "./chat.gif";
import { useEffect, useRef, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { Wordmark } from "../../components/wordmark/Wordmark";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import classNames from "classnames";

type LandingProps = {
  withInitialAnimation: boolean;
};

const Y_OFFSET = 100;
const DURATION = 0.65;
const EASE = "easeOut";
const MARGIN_1 = "-100px";
const MARGIN_2 = "-140px";

const FadeInText = () => {
  return (
    <>
      <img className={styles.headerTextImage} src={landingImage} />
      <h1 className={classNames(styles.headerText, styles.headerTextMobile)}>
        GIVING UNDERWRITING PROFESSIONALS{" "}
        <span className={styles.highlightColor}>SUPERPOWERS</span>
      </h1>
    </>
  );
};

export const Landing: React.FC<LandingProps> = ({ withInitialAnimation }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  const navigate = useNavigate();

  const ref1 = useRef(null);
  const inView1 = useInView(ref1, { once: true, margin: MARGIN_1 });

  const ref2 = useRef(null);
  const inView2 = useInView(ref2, { once: true, margin: MARGIN_2 });

  const ref3 = useRef(null);
  const inView3 = useInView(ref3, { once: true, margin: MARGIN_2 });

  const ref4 = useRef(null);
  const inView4 = useInView(ref4, { once: true, margin: MARGIN_1 });

  const ref5 = useRef(null);
  const inView5 = useInView(ref5, { once: true, margin: MARGIN_1 });

  const ref6 = useRef(null);
  const inView6 = useInView(ref6, { once: true, margin: MARGIN_2 });

  const ref7 = useRef(null);
  const inView7 = useInView(ref7, { once: true, margin: MARGIN_2 });

  const ref8 = useRef(null);
  const inView8 = useInView(ref8, { once: true, margin: MARGIN_1 });

  const ref9 = useRef(null);
  const inView9 = useInView(ref9, { once: true, margin: MARGIN_1 });

  useEffect(() => {
    const handleScroll = () => setUserHasScrolled(true);
    scrollRef.current?.addEventListener("scroll", handleScroll, { once: true });
    return () => scrollRef.current?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.container} ref={scrollRef}>
      {withInitialAnimation ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 4, ease: "easeInOut", delay: 0.5 }}
          className={styles.headerTextImageContainer}
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1.0 }}
            transition={{ duration: 6, ease: "easeInOut", delay: 0.5 }}
            style={{ maxWidth: "100%" }}
          >
            <FadeInText />
          </motion.div>
        </motion.div>
      ) : (
        <FadeInText />
      )}
      <div className={styles.contentContainer}>
        <motion.div
          className={styles.contentPieceText}
          ref={ref5}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView5 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
        >
          <h1 className={styles.headerText}>
            <span className={styles.highlightColor}>AUTOMATE</span> YOUR
            SUBMISSIONS
          </h1>
          <span className={styles.infoText}>
            Tired of spending hours reading through broker emails to find the
            submissions that align with company appetite?
          </span>
          <span className={styles.infoText}>
            Let Pantheon automate your process by parsing policy forms, broker
            emails, SOV spreadsheets, loss history, and more.
          </span>
        </motion.div>
        <motion.div
          className={styles.contentPieceText}
          ref={ref6}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView6 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
          style={{
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <LoadingDocument />
        </motion.div>
      </div>

      <div
        className={classNames(
          styles.contentContainer,
          styles.contentContainerReverse
        )}
      >
        <motion.div
          className={styles.contentPiece}
          ref={ref7}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView7 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
          style={{
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <img src={chatImage} className={styles.imgShadow} />
        </motion.div>
        <motion.div
          className={styles.contentPieceText}
          ref={ref8}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView8 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
        >
          <h1 className={styles.headerText}>
            CHAT WITH{" "}
            <span className={styles.highlightColor}>GENERATIVE AI</span>
          </h1>
          <span className={styles.infoText}>
            Converse with a chatbot that has access to all of the relevant
            information for a specific policy. Find out what you need to know,
            fast.
          </span>
        </motion.div>
      </div>

      <div className={styles.contentContainer}>
        <motion.div
          className={styles.contentPieceText}
          ref={ref1}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView1 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
        >
          <h1 className={styles.headerText}>
            A <span className={styles.highlightColor}>DASHBOARD</span> BUILT
            JUST FOR YOU
          </h1>
          <span className={styles.infoText}>
            Customize your appetite to build a dashboard that works for you.
            Quickly sort, search, and assign submissions to speed up your
            workflow.
          </span>
        </motion.div>
        <motion.div
          className={styles.contentPiece}
          ref={ref2}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView2 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
        >
          <img src={dashboardImage} className={styles.imgFadeOut} />
        </motion.div>
      </div>
      <div
        className={classNames(
          styles.contentContainer,
          styles.contentContainerReverse
        )}
      >
        <motion.div
          className={styles.contentPiece}
          ref={ref3}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView3 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
        >
          <img src={policyImage} className={styles.imgFadeOut} />
        </motion.div>
        <motion.div
          className={styles.contentPieceText}
          ref={ref4}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView4 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
        >
          <h1 className={styles.headerText}>
            <span className={styles.highlightColor}>DEDUCTIBLES</span> AT YOUR
            FINGERTIPS
          </h1>
          <span className={styles.infoText}>
            We extract deductibles and limits from incoming policy forms so you
            don't have to. Then we apply them row by row to the SOV so you can
            send it straight to RMS.
          </span>
          <span className={styles.infoText}>
            Quickly verify deductible information by viewing quoted policy
            excerpts.
          </span>
        </motion.div>
      </div>
      <div className={styles.contentContainer}>
        <motion.div
          className={styles.contentPieceText}
          style={{ alignItems: "center" }}
          ref={ref9}
          initial={{ opacity: 0, y: Y_OFFSET }}
          animate={inView9 && userHasScrolled ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: DURATION, ease: EASE }}
        >
          <h1 className={styles.headerText} style={{ textAlign: "center" }}>
            <span className={styles.highlightColor}>CONTACT</span> US TODAY
          </h1>
          <span className={styles.infoText} style={{ textAlign: "center" }}>
            Ready to reach out and see what Pantheon can do for your business?
            We are always open to building custom solutions and would love to
            chat.
          </span>
          <Button
            label="CONTACT"
            outlined
            className={styles.contactButton}
            onClick={() => navigate(ROUTES.CONTACT)}
          />
        </motion.div>
      </div>
    </div>
  );
};

const LoadingDocument = () => {
  return (
    <div className={styles.loadingDocContainer}>
      <div className={styles.loadingDoc2}>
        <Wordmark />
        <span style={{ height: "16px" }} />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="60%" />
        <span style={{ height: "16px" }} />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="30%" />
      </div>
    </div>
  );
};
