import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import * as client from "../../../client";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import styles from "./EditSovModal.module.css";
import { useUpdateReport } from "../data";
import { isEqual, clone } from "lodash";

type EditSovModalProps = {
  sovs: client.Sov[];
  reportId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const EditSovModal: React.FC<EditSovModalProps> = ({
  sovs,
  reportId,
  visible,
  setVisible,
}) => {
  const [newSovs, setNewSovs] = useState<client.Sov[]>(() =>
    JSON.parse(JSON.stringify(sovs))
  );
  const [isSaving, setIsSaving] = useState(false);
  // If the input sovs change, set is saving to false. This likely means
  // our edits have finished.
  useEffect(() => {
    setIsSaving(false);
    setNewSovs(JSON.parse(JSON.stringify(sovs)));
  }, [sovs]);

  const updateReport = useUpdateReport(reportId);

  const saveEdits = async () => {
    // Is saving will be set to false automatically once this succeeds
    setIsSaving(true);
    updateReport.mutate({ sovs: newSovs });
  };

  const sovsAreEqual = isEqual(sovs, newSovs);

  return (
    <Dialog
      header={
        <div className={styles.buttonContainer}>
          <span style={{ marginRight: "16px" }}>Edit SOV</span>
          <Button
            label="Save Edits"
            disabled={sovsAreEqual}
            onClick={saveEdits}
            loading={isSaving}
          />
          <Button
            severity="secondary"
            label="Clear Edits"
            disabled={sovsAreEqual}
            onClick={() => {
              setNewSovs(JSON.parse(JSON.stringify(sovs)));
            }}
          />
          <EditSheets
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </div>
      }
      visible={visible}
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 72px)",
      }}
      onHide={() => setVisible(false)}
    >
      <TabView>
        <TabPanel header="Occupancy">
          <EditOccupancy
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </TabPanel>
        <TabPanel header="Construction">
          <EditConstruction
            sovs={newSovs}
            setNewSovs={setNewSovs}
            isSaving={isSaving}
          />
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

type EditProps = {
  sovs: client.Sov[];
  setNewSovs: Dispatch<SetStateAction<client.Sov[]>>;
  isSaving: boolean;
};

const EditSheets: React.FC<EditProps> = ({ sovs, setNewSovs, isSaving }) => {
  const filenames = sovs.map((sov) => {
    return { name: sov.filename };
  });

  const selectedFilenames = sovs
    .filter((sov) => sov.is_enabled)
    .map((sov) => {
      return { name: sov.filename };
    });

  return (
    <MultiSelect
      disabled={isSaving}
      value={selectedFilenames}
      onChange={(e) => {
        setNewSovs((sovs: client.Sov[]) => {
          const enabledFilenames = e.value as { name: string }[];
          const newSovs = sovs.map((sov) => ({
            ...sov,
            is_enabled: enabledFilenames.some(
              ({ name }) => name === sov.filename
            ),
          }));

          return newSovs;
        });
      }}
      options={filenames}
      optionLabel="name"
      placeholder="Select Files/Sheets"
      style={{ width: "260px" }}
      selectAllLabel="All"
    />
  );
};

type GroupedAddresses = {
  [key: string]: {
    [key: string]: client.Address[];
  };
};
type CodeCount = {
  code: string;
  codeType: string;
  description: string;
  count: number;
};
const NONE_GIVEN = "NONE GIVEN";
const CODE_SPLITTER = "___";

const textEditor = (options: ColumnEditorOptions, isSaving: boolean) => {
  return (
    <InputText
      disabled={isSaving}
      type="text"
      value={options.value}
      onChange={(e) => {
        options.editorCallback && options.editorCallback(e.target.value);
      }}
    />
  );
};

const EditOccupancy: React.FC<EditProps> = ({ sovs, setNewSovs, isSaving }) => {
  const groupedOccupancies: GroupedAddresses = {};
  for (const sov of sovs) {
    if (sov.is_enabled) {
      for (const address of sov.addresses) {
        if (address.occupancy_code && address.occupancy_code_type) {
          const occCode =
            address.occupancy_code +
            CODE_SPLITTER +
            address.occupancy_code_type;
          if (!(occCode in groupedOccupancies)) {
            groupedOccupancies[occCode] = {};
          }

          const occDescription = address.occupancy_description || NONE_GIVEN;
          if (!(occDescription in groupedOccupancies[occCode])) {
            groupedOccupancies[occCode][occDescription] = [];
          }
          groupedOccupancies[occCode][occDescription].push(address);
        }
      }
    }
  }

  const occupancyCounts: CodeCount[] = [];
  for (const code of Object.keys(groupedOccupancies)) {
    for (const description of Object.keys(groupedOccupancies[code])) {
      const addresses = groupedOccupancies[code][description];
      const codeParts = code.split(CODE_SPLITTER);
      const occupancyCount: CodeCount = {
        code: codeParts[0],
        codeType: codeParts[1],
        description: description,
        count: addresses.length,
      };
      occupancyCounts.push(occupancyCount);
    }
  }
  occupancyCounts.sort((a, b) => b.count - a.count);

  return (
    <DataTable
      value={occupancyCounts}
      editMode="row"
      onRowEditComplete={(e) => {
        // If there were no edits, exit early
        if (JSON.stringify(e.data) === JSON.stringify(e.newData)) {
          return;
        }
        // Prevent saving the empty string, which can have a bad effect
        if (e.newData.code === "" || e.newData.codeType === "") {
          return;
        }

        setNewSovs((sovs) => {
          const newSovs = [];
          for (const sov of sovs) {
            const newSov = clone(sov);
            for (const address of newSov.addresses) {
              if (
                address.occupancy_code_type === e.data.codeType &&
                address.occupancy_code === e.data.code &&
                (address.occupancy_description || NONE_GIVEN) ===
                  e.data.description
              ) {
                address.occupancy_code = e.newData.code;
                address.occupancy_code_type = e.newData.codeType;
              }
            }
            newSovs.push(newSov);
          }
          return newSovs;
        });
      }}
    >
      <Column
        field="code"
        header="Occupancy Code"
        editor={(options) => textEditor(options, isSaving)}
        style={{ width: "15%" }}
      />
      <Column
        field="codeType"
        header="Code Type"
        editor={(options) => textEditor(options, isSaving)}
        style={{ width: "15%" }}
      />
      <Column
        field="description"
        header="Occupancy Description"
        style={{ width: "35%" }}
      />
      <Column field="count" header="Count" style={{ width: "15%" }} />
      <Column
        rowEditor={true}
        headerStyle={{ width: "10%", minWidth: "8rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};

const EditConstruction: React.FC<EditProps> = ({
  sovs,
  setNewSovs,
  isSaving,
}) => {
  const groupedConstructions: GroupedAddresses = {};
  for (const sov of sovs) {
    if (sov.is_enabled) {
      for (const address of sov.addresses) {
        if (address.construction_code && address.construction_code_type) {
          const conCode =
            address.construction_code +
            CODE_SPLITTER +
            address.construction_code_type;
          if (!(conCode in groupedConstructions)) {
            groupedConstructions[conCode] = {};
          }

          const conDescription = address.construction_description || NONE_GIVEN;
          if (!(conDescription in groupedConstructions[conCode])) {
            groupedConstructions[conCode][conDescription] = [];
          }
          groupedConstructions[conCode][conDescription].push(address);
        }
      }
    }
  }

  const constructionCounts: CodeCount[] = [];
  for (const code of Object.keys(groupedConstructions)) {
    for (const description of Object.keys(groupedConstructions[code])) {
      const addresses = groupedConstructions[code][description];
      const codeParts = code.split(CODE_SPLITTER);
      const constructionCount: CodeCount = {
        code: codeParts[0],
        codeType: codeParts[1],
        description: description,
        count: addresses.length,
      };
      constructionCounts.push(constructionCount);
    }
  }
  constructionCounts.sort((a, b) => b.count - a.count);

  return (
    <DataTable
      value={constructionCounts}
      editMode="row"
      onRowEditComplete={(e) => {
        // If there were no edits, exit early
        if (JSON.stringify(e.data) === JSON.stringify(e.newData)) {
          return;
        }
        // Prevent saving the empty string, which can have a bad effect
        if (e.newData.code === "" || e.newData.codeType === "") {
          return;
        }

        setNewSovs((sovs) => {
          const newSovs = [];
          for (const sov of sovs) {
            const newSov = clone(sov);
            for (const address of newSov.addresses) {
              if (
                address.construction_code_type === e.data.codeType &&
                address.construction_code === e.data.code &&
                (address.construction_description || NONE_GIVEN) ===
                  e.data.description
              ) {
                address.construction_code = e.newData.code;
                address.construction_code_type = e.newData.codeType;
              }
            }
            newSovs.push(newSov);
          }
          return newSovs;
        });
      }}
    >
      <Column
        field="code"
        header="Construction Code"
        editor={(options) => textEditor(options, isSaving)}
        style={{ width: "15%" }}
      />
      <Column
        field="codeType"
        header="Code Type"
        editor={(options) => textEditor(options, isSaving)}
        style={{ width: "15%" }}
      />
      <Column
        field="description"
        header="Construction Description"
        style={{ width: "35%" }}
      />
      <Column field="count" header="Count" style={{ width: "15%" }} />
      <Column
        rowEditor={true}
        headerStyle={{ width: "10%", minWidth: "8rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};
