import { Splash } from "./Splash";
import { useContext, useEffect, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Wordmark } from "./components/wordmark/Wordmark";
import styles from "./LoggedOut.module.css";
import { Company } from "./pages/company/Company";
import { Landing } from "./pages/landing/Landing";
import { motion } from "framer-motion";
import { ThemeContext } from "./themes/Theme";
import { Security } from "./pages/security/Security";
import { TermsOfService } from "./pages/security/TermsOfService";
import { PrivacyPolicy } from "./pages/security/PrivacyPolicy";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ROUTES } from "./routes";
import { Contact } from "./pages/contact/Contact";
import { logEvent } from "./amplitude";
import { debounce } from "lodash";
import { Articles } from "./pages/articles/ArticlesPage";
import { FullArticle } from "./pages/articles/FullArticle";

type LoggedOutProps = {
  onSuccessfulAuth: (clientId: string) => void;
};

// Something about react-router remounts this component during initial page load, so only log one page visit event per 500ms.
const logVisit = debounce((params: { [k: string]: string }) => {
  logEvent("page_visit", { ...params, location: location.hash.substring(1) });
}, 500);

export const LoggedOut: React.FC<LoggedOutProps> = ({ onSuccessfulAuth }) => {
  const [hasShownAdditionalPage, setHasShownAdditionalPage] = useState(false);
  const { setThemeOverride } = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams;
    const params: { [query: string]: string } = {};
    searchParams.forEach((v, k) => {
      params[k] = v;
    });
    logVisit(params);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== ROUTES.LANDING) {
      setHasShownAdditionalPage(true);
    }

    setThemeOverride("dark");

    return () => {
      setThemeOverride(undefined);
    };
  }, [location.pathname, setThemeOverride]);

  return (
    <div className={styles.container}>
      <Toolbar
        className={styles.toolbar}
        start={
          <Wordmark
            onClick={() => navigate(ROUTES.LANDING)}
            className={styles.wordmark}
          />
        }
        end={
          <>
            <Button
              text
              className={styles.linkButton}
              onClick={() => navigate(ROUTES.CONTACT)}
            >
              CONTACT
            </Button>
            <Button
              text
              onClick={() => navigate(ROUTES.ARTICLES)}
              className={styles.linkButton}
            >
              ARTICLES
            </Button>
            <Button
              text
              onClick={() => navigate(ROUTES.SIGN_IN)}
              className={styles.linkButton}
            >
              SIGN IN
            </Button>

            {/*
            We can comment this back in when we want to show company members
            <Button
              text
              onClick={() => setPage("company")}
              className={styles.linkButton}
            >
              COMPANY
            </Button>
        */}
          </>
        }
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.contentContainer}
        key={location.pathname}
        style={
          location.pathname === ROUTES.SECURITY ||
          location.pathname === ROUTES.CONTACT
            ? { justifyContent: "flex-start" }
            : {}
        }
      >
        <Routes>
          <Route
            path={ROUTES.LANDING}
            element={<Landing withInitialAnimation={!hasShownAdditionalPage} />}
          />
          <Route
            path={ROUTES.SIGN_IN}
            element={<Splash onSuccessfulAuth={onSuccessfulAuth} />}
          />
          <Route path={ROUTES.COMPANY} element={<Company />} />
          <Route path={ROUTES.SECURITY} element={<Security />} />
          <Route path={ROUTES.CONTACT} element={<Contact />} />
          <Route path={ROUTES.TOS} element={<TermsOfService />} />
          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.ARTICLES} element={<Articles />} />
          <Route path="/articles/:id" element={<FullArticle />} />
          <Route path="*" element={<Navigate to={ROUTES.LANDING} />} />
        </Routes>
      </motion.div>
      <div className={styles.footer}>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => navigate(ROUTES.TOS)}
        >
          TERMS OF SERVICE
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => navigate(ROUTES.PRIVACY_POLICY)}
        >
          PRIVACY POLICY
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => navigate(ROUTES.SECURITY)}
        >
          SECURITY
        </Button>
      </div>
    </div>
  );
};
