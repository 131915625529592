import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  UpdateReport,
  listReport,
  listReports,
  listUsers,
  updateReport,
} from "../../api/routes";
import * as client from "../../client";

export const useReports = () => {
  return useQuery({
    queryKey: ["reports"],
    queryFn: async () => {
      return await listReports();
    },
  });
};

export const useReport = (reportId: string) => {
  return useQuery({
    queryKey: ["reports", reportId],
    queryFn: async () => {
      return await listReport(reportId);
    },
  });
};

export const useUpdateReport = (reportId: string) => {
  const key = ["reports", reportId];
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: key,
    mutationFn: async (update: UpdateReport) =>
      await updateReport(reportId, update),
    onSuccess: (data, update) => {
      // invalidate prefix matches against queryKeys
      // currently, only assignee and status affect the list view
      if (update.assignee || update.status) {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
      }
      queryClient.setQueryData(["reports", reportId], data);
    },
  });
};

export const useUsers = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return await listUsers();
    },
  });
};

export const useUserById = (
  userId: string | undefined | null
): client.User | undefined => {
  const users = useUsers();
  if (!userId) return undefined;
  return (users.data?.all_company_users ?? []).find((u) => u.id === userId);
};
