import styles from "./PrintableReport.module.css";
import * as client from "../../client";
import { LayersCard } from "./layers/LayersCard";
import { Wordmark } from "../../components/wordmark/Wordmark";
import { RiskDetailsCardPrintVariant } from "./RiskDetailsCard";
import { CompanyInfoHeader } from "./CompanyInfoHeader";
import "./PrintableReportStyles.css";
import { sortedLayersFromReport } from "./layers/util";
import { SummaryCard } from "./summary/SummaryCard";

type PrintableReportProps = {
  specialtyPropertyInfo: client.ReportResponse;
};

export const PrintableReport: React.FC<PrintableReportProps> = ({
  specialtyPropertyInfo,
}) => {
  return (
    <div className={styles.printContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>
          {specialtyPropertyInfo.report_json.company_info.company_name}
        </h2>
        <Wordmark height="24px" themeOverride="light" />
      </div>
      <CompanyInfoHeader specialtyPropertyInfo={specialtyPropertyInfo} />
      <div className={styles.pageBreakAvoid}>
        <RiskDetailsCardPrintVariant
          specialtyPropertyInfo={specialtyPropertyInfo}
        />
      </div>
      <div className={styles.pageBreakAvoid}>
        <LayersCard
          sortedLayers={sortedLayersFromReport(specialtyPropertyInfo)}
        />
      </div>
      <div className={styles.pageBreakAvoid}>
        <SummaryCard
          specialtyPropertyInfo={specialtyPropertyInfo}
          forPrint={true}
        />
      </div>
    </div>
  );
};
