import { Card } from "primereact/card";
import styles from "./Contact.module.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { logAmplitudeEventRemotely } from "../../api/routes";

export const Contact = () => {
  const toast = useRef<Toast>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const sendMessage = () => {
    logAmplitudeEventRemotely("contact_form", {
      name: name,
      email: email,
      company: company,
      message: message,
    });
    setName("");
    setEmail("");
    setCompany("");
    setMessage("");
    toast.current?.show({
      severity: "success",
      summary: "Message Sent",
      detail: "Thanks for reaching out! We'll be in touch shortly.",
      life: 5000,
    });
  };

  return (
    <div className={styles.container}>
      <Toast ref={toast} />
      <h1 className={styles.headerText}>HOW CAN WE HELP?</h1>
      <span className={styles.subtext}>
        Fill out the information below and we'll be in touch.
      </span>
      <Card>
        <div className={styles.cardContainer}>
          <div className={styles.inputContainer}>
            <span>Name</span>
            <InputText
              type="text"
              placeholder="Jane Doe"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className={styles.inputContainer}>
            <span>Email</span>
            <InputText
              type="text"
              placeholder="jane@company.com"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className={styles.inputContainer}>
            <span>Company</span>
            <InputText
              type="text"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
          </div>
          <div className={styles.inputContainer}>
            <span>Message</span>
            <InputTextarea
              autoResize={true}
              rows={5}
              style={{ width: "100%" }}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
          </div>
          <div>
            <Button
              label="SEND MESSAGE"
              disabled={!(name && email && company && message)}
              onClick={sendMessage}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
