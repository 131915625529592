import { UwAssistant } from "./pages/uw-assistant/UwAssistant";
import { Menubar } from "primereact/menubar";
import styles from "./LoggedIn.module.css";
import { Wordmark } from "./components/wordmark/Wordmark";
import { Dashboard } from "./pages/specialty-assistant/dashboard/Dashboard";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES } from "./routes";
import { useFeaturesForComapny } from "./CompanyConfig";
import { Renewal } from "./pages/renewal/Renewal";
import { PantheonAvatar } from "./components/avatar/Avatar";
import { PantheonOnly } from "./dev";
import { useUsers } from "./pages/specialty-assistant/data";
import { displayNameForUser } from "./util";
import { Articles } from "./pages/articles/ArticlesPage";
import { FullArticle } from "./pages/articles/FullArticle";

export const LoggedIn = ({ clientId }: { clientId: string }) => {
  const navigate = useNavigate();
  const features = useFeaturesForComapny(clientId, navigate);
  const me = useUsers().data?.current_user;

  return (
    <div className={styles.loggedInContainer}>
      <Menubar
        start={
          <Wordmark
            className={styles.wordMarkPadding}
            height="32px"
            onClick={() => navigate(features.defaultRoute)}
          />
        }
        model={features.menuItems}
        className={styles.menubar}
        end={
          <PantheonOnly>
            <PantheonAvatar
              label={me ? displayNameForUser(me) : "loading"}
              size="large"
            />
          </PantheonOnly>
        }
      />
      <Routes>
        {features.isStrEnabled && (
          <Route path={ROUTES.STR} element={<UwAssistant />} />
        )}
        {features.isRenewalEnabled && (
          <Route path={ROUTES.RENEWAL} element={<Renewal />} />
        )}
        {(features.isPropertyAssistantEnabled ||
          features.isCasualtyAssistantEnabled) && (
          <Route path={ROUTES.DASHBOARD + "/*"} element={<Dashboard />} />
        )}
        <Route path={ROUTES.ARTICLES} element={<Articles />} />
        <Route path="/articles/:id" element={<FullArticle />} />
        <Route path="*" element={<Navigate to={features.defaultRoute} />} />
      </Routes>
    </div>
  );
};
