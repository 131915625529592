import { Avatar } from "primereact/avatar";
import { Tooltip } from "primereact/tooltip";
import styles from "./Avatar.module.css";
import classNames from "classnames";
import * as jdenticon from "jdenticon";
import { useMemo } from "react";

type PantheonAvatarProps = {
  /* If label is undefined, a question mark for "unassigned" will be displayed */
  label?: string;
  size?: "small" | "large";
};

export const generateIdenticonDataUrl = (val: string): string | undefined => {
  // Try changes at https://jdenticon.com/icon-designer.html?config=ffffffff10c32843234c1840
  const jdenticonConfig = {
    hues: [195],
    lightness: {
      color: [0.35, 0.77],
      grayscale: [0.25, 0.65],
    },
    saturation: {
      color: 0.4,
      grayscale: 0.68,
    },
    backColor: "#fff",
  };
  const canvas = document.createElement("canvas");
  canvas.width = canvas.height = 64;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    jdenticon.drawIcon(ctx, val, 64, jdenticonConfig);
    return canvas.toDataURL();
  }
};

export const PantheonAvatar: React.FC<PantheonAvatarProps> = ({
  label,
  size = "small",
}) => {
  const image = useMemo(
    () => (label !== undefined ? generateIdenticonDataUrl(label) : undefined),
    [label]
  );

  return (
    <>
      <Tooltip target={"." + styles.avatar} position="left" />
      <Avatar
        shape="circle"
        className={classNames(
          styles.avatar,
          size === "small" ? styles.avatarSmall : styles.avatarLarge
        )}
        icon="pi pi-question-circle"
        image={image}
        data-pr-tooltip={label ?? "Unassigned"}
        data-pr-showdelay={500}
      />
    </>
  );
};
