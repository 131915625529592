import * as client from "../../../client";

export const sortedLayersFromReport = (
  specialtyPropertyInfo: client.ReportResponse
) => {
  const layers = specialtyPropertyInfo.report_json.layer_info.map((layer) => ({
    startingPoint: layer.starting_point,
    range: layer.range,
    target: layer.target,
    rated: layer.rated,
  }));

  return layers.sort((a, b) => {
    if (a.startingPoint === b.startingPoint) {
      return b.range - a.range;
    }

    return b.startingPoint - a.startingPoint;
  });
};
